import React from 'react'
import { css } from '@emotion/core'

import { mq } from './styles/Layout.styles'
import Div from './Div/Div.styles'
import Body2 from './Body2'
import Paragraph from './Paragraph'
import ds from '../theme'
import ReactTextCollapse from 'react-text-collapse' 
import Check from '@material-ui/icons/CheckCircle'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import {
  licenseHeader,
  licenceContent,
  collapseItem,
  valueContent,
  textContent
} from './styles/License.styles'

import { btnBuyLicence } from './styles/ContainedButton.styles'

const TEXT_COLLAPSE_OPTIONS = { 
  collapse: false, 
  collapseText: 'Continue lendo', 
  expandText: '- Ocultar detalhes', 
  minHeight: 135, 
  maxHeight: 485,
  textStyle: { 
    color: '#737474', 
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 1.71,
    marginTop: '10px',
    textAlign: 'left',
    width: '100%',
    cursor: 'pointer'
  }
}

const LicenceCpanel = ({
  licenceImg,
  licenceDescriptionMore,
  licenceDescription1,
  licenceDescription2,
  licenceDescription3,
  licenceDescription4,
  licencePriceClient,
  licencePriceClientQtd,
  buyLink
}) => {
  return (
    <div
      style={{
        backgroundColor: '#ffffff',
        borderRadius: 18,
        paddingBottom: 50
      }}
      css={
        [css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        )
      ]
      }
    >
      <Div
        flexDirection='column'
        alignItems='flex-start'
        textAlign='left'
        style={{
          padding: '0px 24px',
        }}
      >
        <Div css={licenseHeader}>
          <img src={licenceImg} style={{ width: 200 }} />
        </Div>
        <div
          css={collapseItem}
        >
          <ReactTextCollapse
            options={TEXT_COLLAPSE_OPTIONS}
          >
            {/* <Paragraph
              style={{ 
                width: 280,
                textAlign: 'left',
                alignItems: 'center',
                display: 'flex',
                marginBottom: 16
              }}
            >
              {licenceDescription}
            </Paragraph> */}
            <Paragraph
              style={{ 
                width: 280,
                textAlign: 'left',
                alignItems: 'center',
                display: 'flex'
              }}
            >
              {licenceDescriptionMore}
            </Paragraph>
            <Div
              css={licenceContent}
            >
              <Div
                flexDirection={['row']}
              >
                <Check 
                  style={{
                    color: '#50e3dc',
                    marginRight: 12
                  }}
                />
                {licenceDescription1}
              </Div>
              <Div
                flexDirection={['row']}
              >
                <Check 
                  style={{
                    color: '#50e3dc',
                    marginRight: 12
                  }}
                />
                {licenceDescription2}
              </Div>
              <Div
                flexDirection={['row']}
              >
                <Check 
                  style={{
                    color: '#50e3dc',
                    marginRight: 12
                  }}
                />
                {licenceDescription3}
              </Div>
              <Div
                flexDirection={['row']}
              >
                <Check 
                  style={{
                    color: '#50e3dc',
                    marginRight: 12
                  }}
                />
                {licenceDescription4}
              </Div>
            </Div>
          </ReactTextCollapse>
        </div>
        <Div
          style={{
            width: 280,
            textAlign: 'left',
            display: 'flex'
          }}
        >
          <Body2 
            style={{
              fontSize: 14,
              color: 'rgba(0, 0, 0, 0.6)',
              marginTop: 96,
              marginBottom: 24,
              textAlign: 'left',
              fontStyle: 'italic',
              lineHeight: 0
            }}>
            Valor mensal
          </Body2>
          <RadioGroup aria-label="gender" name="gender1" >
            <FormControlLabel 
              value="opt1" 
              control={<Radio />}
              label={(
                <>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Body2
                      color='black'
                      variant='mediumEmphasis'
                      css={valueContent}
                    >
                      R$ {licencePriceClient}
                    </Body2>
                    <Body2
                      color='black'
                      variant='mediumEmphasis'
                      css={textContent}
                    >
                      {licencePriceClientQtd}
                    </Body2>
                  </div>
                </>
              )} 
            />
          </RadioGroup>
        </Div>
        <Div
          alignItems='center'
        >
          <a
            href={buyLink}
            css={btnBuyLicence}
            style={{
              marginTop: 60,
            }}
          >
            COMPRAR
          </a>
        </Div>
      </Div>
    </div>
  )
}

export default LicenceCpanel
