import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div/Div.styles'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'
import CPanel from '../images/licenses/cpanel.png'
import CloudLinux from '../images/licenses/cloudlinux.png'
import FantasticoDeluxe from '../images/licenses/fantastico-deluxe.png'
import Imunufy from '../images/licenses/imunufy.png'
import kernelCare from '../images/licenses/kernel-care.png'
import Rvskin from '../images/licenses/rvskin.png'
import Softaculous from '../images/licenses/softaculous.png'
import WhmExtra from '../images/licenses/whm-extra.png'
import WHMSonic from '../images/licenses/whm-sonic.png'
import MagicSpam from '../images/licenses/magicspam.png'

import { Container } from '../components/styles/Layout.styles'
import LicenceCpanel from '../components/LicencesItemCpanel'
import LicenceDeluxe from '../components/LicencesItemDELUXE'
import LicenceWHMSonic from '../components/LicenceItemWHMSonic'
import LicenceWHMXTRA from '../components/LicencesItemWHMXTRA'
import LicenceSoftaculous from '../components/LicencesItemSOFTACULOUS'
import LicenceItemRVSKIN from '../components/LicenceItemRVSKIN'
import LicenceItemCLOUDLINUX from '../components/LicenceItemCLOUDLINUX'
import LicenceItemIMUNIFY360 from '../components/LicenceItemIMUNIFY360'
import LicenceItemKERNELCARE from '../components/LicenceItemKERNELCARE'
import LicenceItemMAGICSPAN from '../components/LicenceItemMAGICSPAM'

import { 
  styledLicense,
  header,
  licenses
} from '../components/styles/License.styles'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <Fragment>
      <section css={styledLicense}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H4>
                <span>licenças</span><br />
                Encontre ofertas exclusivas de licenças com a gente
              </H4>
              <Paragraph style={{ marginTop: 16 }}>Através de nossas fortes parcerias com os melhores fornecedores de softwares, somos capazes de oferecer licenças com descontos em produtos de terceiros. Confira e garanta as suas! </Paragraph>
            </Column>
          </Row>
        </Container>
      </section>
      <section css={licenses}>
        <Container>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'column', 'row']}
            alignItems={'flex-start'}
          >
            <Column
              mb={[16, 16, 24, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <LicenceItemMAGICSPAN
                  licenceImg={MagicSpam}
                  licenceDescriptionMore={`MagicSpam é um software Anti-Spam que é instalado diretamente no servidor de e-mail ou painel de controle, tornando a proteção contra spam simples de instalar e de usar. Pensado para proteger usuários e domínios ilimitados por apenas alguns centavos por dia, ele foi projetado para reduzir a sobrecarga, a largura de banda e o backscatter resultantes de ataques de spam, bloqueando o spam antes que ele seja processado e chegue à sua caixa de entrada.`}
                  licenceDescription1={`Atualizações automáticas`}
                  licenceDescription2={`Preferências personalizadas`}
                  licenceDescription3={`Estatísticas ao vivo`}
                  licenceDescription4={`Bloqueia trojans e bots`}
                  licencePriceClient={'96,00'}
                  buyLink={'https://core.hostdime.com.br/licenses/magicspam'}
                />
              </Div>
            </Column>
            <Column
              mb={[16, 16, 24, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <LicenceCpanel
                  licenceImg={CPanel}
                  licenceDescription={`Através de nossa parceria com o cPanel, oferecemos o software líder de controle e administração de hospedagens.`}
                  licenceDescriptionMore={`As licenças do cPanel mensais fazem parte dos nossos produtos mais vendidos. Você terá acesso a upgrades e atualizações gratuitas por todo o período que manter a sua licença ativa com a HostDime Brasil!`}
                  licenceDescription1={`Totalmente personalizável com a sua marca`}
                  licenceDescription2={`Interface completa para administrar seu servidor`}
                  licenceDescription3={`Painel para utilização dos clientes finais`}
                  licenceDescription4={`Diferentes interfaces para públicos distintos`}
                  licenceDescription5={`Processo de migração facilitado para a maioria dos hosts`}
                  licenceDescription6={`Scripts pré-instalados & instalador Fantastico!`}
                  licenceDescription7={`Ativação instantânea após a compra`}
                  licencePriceClientHostdime={'249,00'}
                  buyLink={'https://core.hostdime.com.br/purchaselicense/index/id/a80198fb6c991ac5715f'}
                  buyLink2={'https://core.hostdime.com.br/purchaselicense/index/id/9da9fbb75f77a72d4d43'}
                />
              </Div>
            </Column>
            <Column
              mb={[16, 16, 24, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <LicenceDeluxe
                  licenceImg={FantasticoDeluxe}
                  licenceDescription={`O Fantastico é o mais conhecido e o mais estável software de instalação de aplicações, além de ter sido o primeiro a surgir do seu grupo. Usado para instalar milhões de instâncias dos principais softwares por milhões de usuários.`}
                  licenceDescriptionMore={`Os mais de 60 softwares do Fantastico podem ser instalado com apenas um clique. Os arquivos são copiados e os bancos de dados são criados automaticamente. Ele também permite que os usuários instalem softwares não apenas no domínio principal, mas também em subdomínios ou domínios adicionais da conta. As instalações podem ser habilitadas ou desabilitadas para usuários específicos.`}
                  licenceDescription1={`Wordpress`}
                  licenceDescription2={`Joomla!`}
                  licenceDescription3={`Drupal`}
                  licenceDescription4={`Xoops`}
                  licenceDescription5={`phpBB`}
                  licencePriceClient={'35,00'}
                  licencePriceClientHostdime={'25,00'}
                  buyLink={'https://core.hostdime.com.br/purchaselicense/index/id/0f336850c00399c3ad4d'}
                  buyLink2={'https://core.hostdime.com.br/purchaselicense/index/id/2e390a1996001c66d4a2'}
                />
              </Div>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'column', 'row']}
            alignItems={'flex-start'}
          >
            <Column
              mb={[16, 16, 24, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <LicenceWHMSonic
                  licenceImg={WHMSonic}
                  licenceDescription={`WHMSonic é um plugin desenvolvido para o cPanel que permite a você e a seus clientes gerenciarem serviços de streaming diretamente do cPanel.`}
                  licenceDescriptionMore={`O WHMSonic é um dos plugins mais populares do WHM/cPanel, de fácil gerenciamento. O plugin é instalado em poucos segundos e permite que você ofereça streaming de mídia SHOUTcast, AutoDJ e revenda de rádios online do Servidor Dedicado ou VPS sem que seja necessário acessar sua sessão SSH.`}
                  licenceDescription1={`Suporta 8 diferentes idiomas`}
                  licenceDescription2={`Integração com Sistemas de Pagamentos (WHMCS, AWBS, Clientexec)`}
                  licenceDescription3={`Crie players em flash facilmente`}
                  licenceDescription4={`Crie contas de revenda do WHMSonic para os seus atuais revendedores do WHM`}
                  licenceDescription5={`Suporte player em flash, Mediaplayer, Quicktime, RealPlayer e Winamp`}
                  licencePriceClient={'30,00'}
                  licencePriceClientHostdime={'25,00'}
                  buyLink={'https://core.hostdime.com.br/purchaselicense/index/id/3f463b61eb2e76a62e74'}
                  buyLink2={'https://core.hostdime.com.br/purchaselicense/index/id/a820766feaff205ae55b'}
                />
              </Div>
            </Column>
            <Column
              mb={[16, 16, 24, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <LicenceWHMXTRA
                  licenceImg={WhmExtra}
                  licenceDescription={`O WHMXtra instala firewalls, proteção DDOS, FFMPEG e muito mais. Tudo em WHMXtra é projetado para poupar tempo e dinheiro quando se trata em proteger, reparar e gerenciar o seu servidor!`}
                  licenceDescriptionMore={`Com uma licença do WHMXtra você terá acesso a quase 30 diferentes ferramentas de segurança, incluindo firewalls para bloqueio de IPs, monitores de socket, monitor de ataques DoS e ferramentas de deflate, scanners de segurança para localizar proxies abertos ou shell scripts no seu servidor e muito mais!`}
                  licenceDescription1={`Instalar o FFMPEG`}
                  licenceDescription2={`Firewalls`}
                  licenceDescription3={`Proteção DDoS`}
                  licenceDescription4={`Correção de Problemas no MySQL`}
                  licenceDescription5={`Localizar Arquivos Ilegais`}
                  licenceDescription6={`Monitoramento do Servidor`}
                  licenceDescription7={`E outras 160 funções`}
                  licencePriceClient={'35,00'}
                  licencePriceClientHostdime={'25,00'}
                  buyLink={'https://core.hostdime.com.br/purchaselicense/index/id/64517053baf0de1e0b0a'}
                  buyLink2={'https://core.hostdime.com.br/purchaselicense/index/id/6f589d4a59db65dbcedf'}
                />
              </Div>
            </Column>
            <Column
              mb={[16, 16, 24, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <LicenceSoftaculous
                  licenceImg={Softaculous}
                  licenceDescription={`O Softaculous é um novo instalador automático com mais de 140 softwares que está sendo sempre atualizado com novos scripts. Estes scripts suprem a grande maioria das necessidades que seus clientes venham a ter.`}
                  licenceDescriptionMore={`Todos os mais de 140 softwares podem ser instalados com apenas um clique dos seus usuários. Os arquivos e bancos de dados são configurações automaticamente para que o software seja imediatamente liberado para os seus clientes com o mínimo de esforço e conhecimento possível.`}
                  licenceDescription1={`Wordpress`}
                  licenceDescription2={`Joomla!`}
                  licenceDescription3={`Drupal`}
                  licenceDescription4={`phpBB`}
                  licenceDescription5={`Coppermine`}
                  licenceDescription6={`E outros 140 scripts!`}
                  licencePriceClient={'35,00'}
                  licencePriceClientHostdime={'25,00'}
                  buyLink={'https://core.hostdime.com.br/purchaselicense/index/id/39dfdc09a2db53a97aeb'}
                  buyLink2={'https://core.hostdime.com.br/purchaselicense/index/id/55819e36822f48d0c496'}
                />
              </Div>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'column', 'row']}
            alignItems={'flex-start'}
          >
            <Column
              mb={[16, 16, 24, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <LicenceItemRVSKIN
                  licenceImg={Rvskin}
                  licenceDescription={`RVSkin é um gerenciador de temas em várias línguas adaptado especificamente para servidores cPanel. Use RVSkin para divulgar sua empresa e ajudar o seu negócio a se destacar no mercado.`}
                  licenceDescriptionMore={`O RVSkin suporta atualmente 24 diferentes idiomas em sua licença simples. Traduza a interface do seu cPanel para qualquer um destes 24 idiomas de força fácil e rápida, mantendo o seu painel personalizado para os seus clientes de todo o mundo.`}
                  licenceDescription1={`Interface multi idiomas`}
                  licenceDescription2={`Painel administrativo e para usuários finais`}
                  licenceDescription3={`Gerenciador de funções disponíveis`}
                  licenceDescription4={`Gerenciador de funções para webmail`}
                  licenceDescription5={`Header personalizável`}
                  licencePriceClient={'35,00'}
                  licencePriceClientHostdime={'25,00'}
                  buyLink={'https://core.hostdime.com.br/purchaselicense/index/id/9226d4a8a46cd7ca0c99'}
                  buyLink2={'https://core.hostdime.com.br/purchaselicense/index/id/95abd1b14c9777066278'}
                />
              </Div>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            alignItems={'center'}
            mt={50}
            mb={30}
          >
            <H5>Exclusivas para<br /> Clientes Hostdime</H5>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'column', 'row']}
            alignItems={'flex-start'}
          >
            <Column
              mb={[16, 16, 24, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <LicenceItemCLOUDLINUX
                  licenceImg={CloudLinux}
                  licenceDescriptionMore={`O CloudLinux OS apresenta as tecnologias mais atuais para alojamento compartilhado em seu kernel. Ele permite que você combine esses recursos com todas as suas ferramentas e faça a integração aos principais painéis de controle.`}
                  licenceDescription={`CloudLinux mantém seus servidores estáveis e seus clientes satisfeitos, mesmo sob extremo estresse. Isto ocorre porque cada conta é isolada em seu ambiente virtualizado (LVE) com recursos pré-configurados por você.`}
                  licenceDescription1={`Garante maior densidade e segurança para o servidor`}
                  licenceDescription2={`Previne violações de segurança ao separar inquilinos um do outro`}
                  licenceDescription3={`Elimina picos de recursos e sobrecargas do servidor`}
                  licenceDescription4={`Compatível com todos os principais painéis de controle`}
                  licencePriceClient={'60,00'}
                  buyLink={'https://core.hostdime.com.br/purchaselicense/index/id/e61dc83430f632161a0c'}
                />
              </Div>
            </Column>
            <Column
              mb={[16, 16, 24, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <LicenceItemIMUNIFY360
                  licenceImg={Imunufy}
                  licenceDescription={`O Imunify360 é um sistema de segurança muito sofisticado, que coleta e processa constantemente um grande número de informações sobre novos ataques em servidores ao redor do mundo.`}
                  licenceDescriptionMore={`Seus sites e servidores Linux são uma parte vital dos seus negócios, é imprescindível por protegê-los de todos os tipos de ataques digitais. O Imunify360 é uma solução de segurança automatizada, de defesa proativa, que protege contra malwares, mantém kernels seguros e você informado com informações relevantes.`}
                  licenceDescription1={`Scanner de malware automatizado com limpeza`}
                  licenceDescription2={`WAF avançado com conjunto de regras de aprendizado de máquina`}
                  licenceDescription3={`Detecção e proteção contra intrusos`}
                  licenceDescription4={`Defesa proativa para sites PHP`}
                  licenceDescription5={`Gerenciamento de patches de software`}
                  licenceDescription6={`WebShield com CAPTCHA integrado`}
                  licenceDescription7={`Integração de painéis de hospedagem`}
                  licencePriceClient={'24,99'}
                  buyLink={'https://core.hostdime.com.br/purchaselicense/index/id/4338848bfa5325b85105'}
                />
              </Div>
            </Column>
            <Column
              mb={[16, 16, 24, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <LicenceItemKERNELCARE
                  licenceImg={kernelCare}
                  licenceDescription={`KernelCare é um serviço que fornece atualizações importantes para o seu kernel em execução, eliminando a necessidade de reiniciar o servidor.`}
                  licenceDescriptionMore={`O KernelCare possui uma instalação fácil e rápida, sendo necessários apenas alguns minutos para ser concluída. Além disso, funciona bem em vários tipos de servidores e ambientes - servidores físicos, ambientes virtuais e até mesmo em servers sem acesso à internet.`}
                  licenceDescription1={`Instala novos patches, correções e atualizações automaticamente`}
                  licenceDescription2={`Disponível para sistemas Linux, incluindo CentOS, RHEL, Ubuntu e muitos outros`}
                  licenceDescription3={`Proatividade constante na checagem de novos patches`}
                  licencePriceClient={'25,00'}
                  buyLink={'https://core.hostdime.com.br/purchaselicense/index/id/0d89769d20769b7d85bc'}
                />
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title='Licenças | Exclusividade para clientes HostDime' 
        description='Clientes HostDime têm licenças de softwares dos nossos parceiros com desconto exclusivo. Confira as empresas participantes!' 
      />
      <PageTemplate
        title='Licenças'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage